@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "sass:math";

.text-video-block {
  &__container.switcher {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    --gutter: var(--s1);
    --threshold: 40rem;


    &--reversed {
      flex-direction: row-reverse;
    }
  }

  &__container {
    position: relative;
  }

  &__video-wrapper {
    overflow: hidden;
    position: relative;
  }

  &__video {
    width: 100%;
    height: 100%;
  }

  h2.text-video-block__heading--sub {
    font-size: var(--s4);
  }
  .text-video-block__heading--descr {
    p {
      font-size: var(--s1);
    }
  }

  &__heading {
    &--sub {
      color: $dark-blue;
      font-size: var(--s4);
      font-weight: 700;
      line-height: 110%;
      font-family: $header-font;
    }

    &--descr {
      font-family: $header-font;
      font-size: var(--s2);
      line-height: normal;
      font-weight: 400;
      color: $dark-blue;
    }
  }

  &__button {
    margin-top: var(--s3);
    font-weight: 700;
    padding: var(--s0) var(--s1) !important;
    font-size: var(--s0);
    line-height: 1;
  }

  &__video-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    color: $primary-green;
    cursor: pointer;

    svg {
      width: 60px;
      height: 60px;
    }
  }

  &__video {
    --radius: 140px;
    height: 100%;
    object-fit: scale-down;
    border-top-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);

    &--cover {
      object-fit: cover;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }

  .breadcrumbs__section {
    position: absolute;
    top: 0;
  }
  .modal {
    z-index: 1051;
  }

}
