@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.blog-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px 0 80px;
  background-color: $broken-white;
  height: 100vh;


  &__categories {
    display: flex;
    justify-content: center;
    padding: 15px 0 35px;
  }

  &__category {
    color: #23527c;
    border-color: #23527c;

    padding: 4px 27px;
    margin: 15px;
    white-space: nowrap;

    border-width: 4px;
    border-style: solid;
    border-radius: 21px;

    font-size: 17px;
    font-weight: bold;

    opacity: 0.5;

    &:hover, &:focus {
      text-decoration: none;
      opacity: 1;
    }

    &--selected {
      opacity: 1;
    }

    &--green {
      border-color: $tag-green;
      color: $tag-green;

      &:hover, &:focus {
        border-color: $tag-green !important;
        color: $tag-green !important;
      }
    }

    &--orange {
      border-color: $tag-orange;
      color: $tag-orange;

      &:hover, &:focus {
        border-color: $tag-orange !important;
        color: $tag-orange !important;
      }
    }

    &--purple {
      border-color: $tag-purple;
      color: $tag-purple;

      &:hover, &:focus {
        border-color: $tag-purple !important;
        color: $tag-purple !important;
      }
    }

    &--red {
      border-color: $tag-red;
      color: $tag-red;

      &:hover, &:focus {
        border-color: $tag-red !important;
        color: $tag-red !important;
      }
    }

    &--blue {
      border-color: $tag-blue;
      color: $tag-blue;

      &:hover, &:focus {
        border-color: $tag-blue !important;
        color: $tag-blue !important;
      }
    }

    &--stamp {
      opacity: 1;
      padding: 3px 19px;
      margin: 5px;
      font-size: 13px;
      background-color: $white;

      &-green {
        border-color: rgba($tag-green, 1);
      }

      &-orange {
        border-color: rgba($tag-orange, 1);
      }

      &-purple {
        border-color: rgba($tag-purple, 1);
      }

      &-red {
        border-color: rgba($tag-red, 1);
      }

      &-blue {
        border-color: rgba($tag-blue, 1);
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }

  &__pagination-button {
    padding: 2px 10px;
    margin: 15px;
    font-size: 20px;
    font-weight: bold;
    color: $tag-blue;
    border: 2px solid $tag-blue;
    border-radius: 5px;

    transition: all 0.1s ease;

    &:hover, &:focus {
      text-decoration: none;
      background-color: $tag-blue;
      color: $white;
    }

    &--disabled {
      pointer-events: none;
      cursor: default;
      border-color: $inactive-grey;
      color: $inactive-grey;
    }
  }

  &__empty {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }

  &__blog-posts {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }

  &__table {
    display: flex;
    flex-wrap: wrap;
    width: 1140px;
    padding-top: 30px;
  }

  &__stamps {
    display: flex;
  }
}

//@media (max-width: $xs-screen) {
//  .blog-overview {
//    &__subtitle {
//      font-size: var(--s0);
//      padding: 15px 15px 0;
//      width: 100%;
//      text-align: center;
//    }
//
//    &__categories {
//      flex-direction: column;
//      align-items: center;
//      padding: 15px 0 15px;
//    }
//
//    &__category {
//      margin: 7px;
//    }
//  }
//}
//
//@media (max-width: 1150px) and (min-width: $xs-screen + 1px) {
//  .blog-overview {
//    &__table {
//      width: $xs-screen;
//    }
//  }
//}
