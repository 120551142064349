/**
Common typography settings used for CMS pages
Some guidelines:
- There should be only one h1 per page; typically, it's the title within the page header.
- All section (block) titles should be h2.
- If a section requires a subtitle, it should be h3, followed by h4, and so on.
In other words, there shouldn't be a direct jump from h2 to h4.
*/
@use "../../settings/all" as *;
@use "../../mixins/all" as *;

h1 {
  font-size: 50px;
  line-height: 54px;
  font-weight: 700;
}

h2 {
  font-size: 42px;
  line-height: 46px;
  font-weight: 500;
}

h3 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
}

h4 {
  font-size: 22px;
  line-height: 24px;
}

h5 {
  font-size: var(--s1);
  line-height: 20px;
}

h6 {
  font-size: var(--s0);
  line-height: 18px;
}

h4, h5, h6 {
  margin-top: 5px;
  font-weight: 600;
}

/* TODO Check the color */
h2, h3, h4 {
  color: $dark-blue;
}

@include mobile-screen {
  h1 {
    font-size: 34px;
    line-height: 40px;
  }

  h2 {
    font-size: 28px;
    line-height: 32px;
  }

  h3 {
    font-size: 22px;
    line-height: 26px;
  }

  h4 {
    font-size: var(--s1);
    line-height: 20px;
  }

  h5 {
    font-size: var(--s0);
    line-height: 18px;
  }

  h5 {
    font-size: var(--s-1);
    line-height: 16px;
  }
}
