@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.blog-view {
  display: flex;
  padding: 75px 0 80px;

  background-color: $broken-white;
  justify-content: center;

  &__stamps {
    position: absolute;
    display: flex;
    top: 393px;
    left: 14px;

  }
}

@media (max-width: $xs-screen) {
  .blog-view {
    flex-direction: column;

    &__body {
      padding: 15px;
    }
    &__stamps {
      top: 210px;
    }
  }
}

@media (max-width: 1150px) and (min-width: $xs-screen + 1px){
  .blog-view {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 1151px) {
  .blog-view {
    padding: 75px 50px 80px;
  }
}
