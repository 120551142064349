@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
.pricing {
  @include cms-block();

  &__container {
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 100px;
    column-gap: 0;
  }

  * {
    color: $primary-blue;;
  }

  &__title {
    font-size: 40px;
  }

  &__rate {
    font-size: 28px;
    font-weight: bold;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    width: calc(33.333% - 70px);
  }

  &__image-wrapper {
    height: 110px;
    display: flex;
    align-items: flex-end;
  }

  &__text {
    max-width: 450px;
  }

  .divider {
    position: relative;
    height: auto;
    width: 0;
    border: 1px solid $primary-blue;
    opacity: 0.5;
    margin: 0 50px;

    &:nth-of-type(3n) {
      display: none;
    }
  }

  @include mobile-screen {

    &__container {
      row-gap: 15px;
    }

    &__item {
      width: 100%;
    }

    .divider {
      width: 100%;
      height: 0;
      margin: 25px 0;

      &:nth-of-type(3n) {
        display: block;
      }
    }
  }
}
