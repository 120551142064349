@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
@use "../variables" as *;
.feedback-text-box {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;

  & + & {
    margin-top: 15px;
  }

  &--teacher-portal {
    .feedback-text-box__label {
      max-width: 25%;
      margin-right: 46px;
      text-align: right;
    }

    .feedback-text-box__text {
      width: 633px;
      max-width: 50%;
      height: 181px;
    }
  }

  &__label {
    // box model
    width: 341px;
    margin: 0;

    // text styling
    font-size: var(--s-1);
    font-weight: bold;

    &--light {
      font-weight: 500;
      margin-bottom: 100px;
    }
  }

  &__text {
    background-color: $feedback-input-background;
    width: 500px;
    border: none;
    border-radius: 5px;
    height: 63px;
    padding: 2px 5px;
  }

  &__text-content {
    width: 500px;
    font-size: var(--s0);
    font-weight: bold;
  }
}
