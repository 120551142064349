.center {
  /* ↓ Remove padding from the width calculation */
  box-sizing: content-box;
  /* ↓ The maximum width is the maximum measure */
  max-inline-size: 140ch;
  /* ↓ Only affect horizontal margins */
  margin-inline: auto;
  /* ↓ Apply the minimum horizontal space */
  padding-inline-start: var(--s2);
  padding-inline-end: var(--s2);

  &--small {
    max-inline-size: 90ch;
    padding-inline: 0;
  }

  .center {
    padding-inline: 0;
  }
}
