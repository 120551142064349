@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.gallery-list {
  display: flex;
  flex-direction: column;
  gap: 44px;
  color: $dark-blue;

  &__header {
    display: flex;
    gap: 42px;
  }

  &__title {
    flex-shrink: 0;
    margin: 0;
  }

  &__text {
    p {
      font-size: 20px;
      font-weight: 500;
    }

    &--subheader {
      p {
        font-family: $header-font;
        font-size: 24px;
        font-weight: 400;
      }
    }
  }

  &__blocks {
    display: flex;
    gap: 24px;
  }
}

.gallery-block {
  height: 720px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  padding: 24px;

  &--wide {
    flex-direction: row;
    background-color: #EBF2F5;
    align-items: flex-start;
    justify-content: unset;
    height: unset;
    gap: 16px;
    color: $dark-blue;

    @include sm-screen {
      flex-direction: column;

      .gallery-block__img {
        flex-shrink: 1;
      }
    }

    .gallery-block__title {
      font-size: var(--s1);
      margin: 0;
    }

    .gallery-block__text {
      flex: unset;
    }

    .gallery-block__content {
      background-color: transparent;
      padding: 0;
    }

    .text-image-block__button {
      margin-top: 8px;
    }
  }


  &--horizontal {
    --margin: 110px;
    width: 100%;
    height: 420px;
    margin-bottom: var(--margin);

    .gallery-block__content {
      width: 40%;
      margin-bottom: calc(var(--margin) * -1);
      box-shadow: 0px 0px 30px 0px #0000001A;
    }
  }

  &__img {
    flex-shrink: 0;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      border-radius: 10px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    background-color: white;
    padding: 20px 24px;
    border-radius: 10px;

    .btn {
      margin-top: 0;
      align-self: flex-start;
    }
  }

  &__title {
    font-weight: 400;
    margin: 0 0 10px 0;
  }

  &__text {
    flex: 1;

    p {
      font-size: 1rem !important;
    }
  }
}

.gallery-cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: $dark-blue;

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@include mobile-screen {
  .gallery-list {
    &__header {
      flex-direction: column;
      gap: 12px;
    }

    &__blocks {
      flex-direction: column;
    }
  }

  .gallery-block {
    padding: 75% 8px 8px;
    border-radius: 25px;

    &--wide {
      padding: 16px;

      .text-image-block__button {
        align-self: flex-start;
      }
    }

    &--horizontal {
      .gallery-block__content {
        width: 100%;

        .text-image-block__button {
          align-self: flex-start;
        }
      }
    }
  }

  .gallery-cards {
    grid-template-columns: 1fr;

    &__grid {
      display: flex;
      flex-direction: column;
    }

    .gallery-block {
      flex-direction: column;
    }

    .gallery-block__img {
      height: auto;

      img {
        height: auto;
      }

    }
  }
}
