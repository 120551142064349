@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
@use "../variables" as *;
.feedback-recommend {
  // box model
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 25px;

  // text styling
  font-size: var(--s0);
  // font-weight: bold;

  &__label {
    display: block;
    font-weight: bold;
    margin: 0;
    min-width: 40%;
  }

  &__radios {
    margin-left: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__radio {
    input[type="radio"] {
      display: none;

      &:checked + .feedback-recommend__radio-label .feedback-recommend__radio-circle {
        background-color: $feedback-font-color;
      }
    }
  }

  &__radio-label {
    margin: 0 25px 0 0;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  &__radio-circle {
    display: inline-block;
    height: 23px;
    width: 23px;
    border: solid 1px $feedback-font-color;
    border-radius: 50%;
    margin-right: 5px;
  }
}
