@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.rich-text {
  display: flex;
  justify-content: center;

  &__inner {
    max-width: 65%;
  }

  h2 {
    margin-bottom: 20px;
  }

  p {
    font-size: $text-size;
    line-height: 1.6;
    font-weight: 600;
  }

  @include sm-screen {
    &__inner {
      max-width: 100%;
    }

    p {
      font-size: 18px
    }
  }
}
