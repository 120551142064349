@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
@use "../variables" as *;
.feedback-summary {
  // box model
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  // text styling
  font-size: var(--s0);
  font-weight: bold;
  text-align: center;

  &--stacked {
    display: block;

    .feedback-summary__score {
      width: auto;
      margin-right: 0;
    }
    .feedback-summary__submit {
      width: auto;
      text-align: left;
    }
  }

  &__submit {
    width: 33%;
  }

  &__score {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}
