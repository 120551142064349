@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.two-columns {
  &__left, &__right {
    display: flex;
    flex-direction: column;
    gap: var(--s2);
  }

  &__header {
    font-family: $header-font;
    color: $dark-blue;
    font-size: var(--s3);
    line-height: normal;
    font-weight: 500;
    margin: -1rem 0 0;
  }

  &__text {
    p {
      font-size: var(--s1);
      font-weight: 400;
      margin-block-start: 0;
    }
    & > p {
      margin-block-start: 0;
    }
    h3 {
      line-height: 1.6;
      margin-block-start: 0;
    }
    & > * + h3 {
      margin-block-start: var(--s1);
    }

    a {
      text-decoration: underline;
    }
  }

  &__img {

    img {
      max-width: 100%;
      height: auto;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
  }
}
