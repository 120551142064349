@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $dark-blue;

  &__content {
    display: flex;
    align-items: center;
    gap: 80px;
    margin-top: 40px;

    &--img-small {
      .timeline__steps {
        width: calc(100% - 100% / 3);
      }

      .timeline__img {
        width: calc(100% / 3);
      }
    }

    &--img-medium {
      .timeline__steps, .timeline__img {
        width: 50%;
      }
    }
  }

  &__steps, &__step {
    display: flex;
    flex-direction: column;
  }

  &__steps {
    justify-content: space-between;
    height: 100%;
    counter-reset: step-counter;
    list-style: none;
    padding: 0;
    position: relative;
  }

  &__step {
    position: relative;
    margin: 20px 0;
    padding-left: 50px;

    &::before {
      content: counter(step-counter) "";
      counter-increment: step-counter;
      position: absolute;
      left: 0;
      top: 0;
      background: #FFB600;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      line-height: 1;
      color: $dark-blue;
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      left: 18px;
      top: 40px;
      width: 2px;
      height: calc(100%);
      border-left: 2px dashed #FFB600;
      z-index: 0;
    }

    &:last-child::after {
      content: none;
    }
  }

  &__img {
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__step-title {
    font-family: $header-font;
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
    margin: 0;
  }

  &__step-text {
    p {
      font-size: 18px;
      line-height: 1.6;
      font-weight: 600;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  @include mobile-screen {
    &__step-title {
      font-size: 20px;
      line-height: 30pxx;
    }

    &__content {
      flex-direction: column;
      gap: 38px;
      margin-top: 0;


      &--img-small {
        .timeline__steps {
          width: 100%;
        }

        .timeline__img {
          width: 100%;
        }
      }

      &--img-medium {
        .timeline__steps, .timeline__img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
