@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
@use "../variables" as *;
@use "sass:color";

$feedback-count-indicator-size: 25px;
$feedback-count-indicator-color: $badge-color;

.feedback-btn-goto {
  position: relative;
  color: $feedback-font-color;

  &__num-feedbacks {
    // box model
    display: flex;
    justify-content: center;
    align-items: center;
    width: $feedback-count-indicator-size;
    height: $feedback-count-indicator-size;
    background: $feedback-count-indicator-color;
    border-radius: 50%;

    // document flow
    position: absolute;
    top: -$feedback-count-indicator-size * 0.5;
    right: -$feedback-count-indicator-size * 0.5;

    // text
    font-size: 12px;
    font-weight: bold;
    color: $white;
  }
}

.feedback-btn-create {
  color: $portal-blue;
  font-size: 24px;
  font-weight: bold;

  &:hover {
    color: color.adjust($portal-blue, $lightness: 10%);
  }
}
