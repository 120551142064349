@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.steps-block {
  @include cms-block();
  flex-wrap: wrap;

  &__title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 0;
  }

  &__step {
    width: calc(100% / 3 - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 10px;
    height: 320px;
  }

  &__header {
    position: relative;
    margin-bottom: 50px;
    color: $text;
    width: 100%;
    min-height: 40px;
    overflow-wrap: break-word;
    hyphens: auto;


    &::after {
      content: '';
      height: 3px;
      width: 40px;
      margin: 10px 0;
      border-radius: 3px;
      background-color: #1B3151;
      position: absolute;
      bottom: -45px;
      left: 0;
    }
  }

  @media screen and (max-width: 767px) {
    &__step {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
