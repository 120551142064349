@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.blog-post {
  padding: 30px;
  margin: 30px 15px 0;
  position: relative;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.1);

  ul {
    list-style: initial;
    padding-left: 40px;
  }

  &__image, img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    max-width: 100%;
    height: auto;
  }
  &__image-wrapper {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 415px;
    overflow: hidden;
  }
  &__body {
    padding: 3rem!important;
  }
  &__info {
    font-weight: 400;
    line-height: 1.5;
  }
  &__title {
    margin-bottom: .5rem;
    margin-top: 0px;
    font-weight: 500;
    line-height: 1.2;
  }
  &__info, &__title {
    color: $color-nav !important;
  }
  &__text {
    color: $grey;
    font-size: var(--s0);

    .image, img {
      max-width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: $xs-screen){
  .blog-post {
    margin: 0;
    width: 100%;

    &__image, img {
      max-width: 350px;
    }
    &__image-wrapper {
      height: 230px;
    }
    &__body {
      padding: 25px 15px !important;
    }
  }
}


@media (max-width: 1150px) and (min-width: $xs-screen + 1px){
  .blog-post {
    margin-right: unset;
  }
}
