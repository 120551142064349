@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.suggested-blog-post {
  &.box {
    background-color: #EBF2F5;
  }
  color: $dark-blue;
  border-radius: var(--s-2);
  cursor: pointer;
  text-align: left;

  &.stack {
    justify-content: space-between;
    align-items: flex-start;
  }

  .btn__primary {
    background-color: #C2DFF3;
    box-shadow: none;
    width: fit-content;

    &:hover {
      background-color: #C2DFF3 !important;
      border-color: #C2DFF3 !important;
    }
  }

  &__image {
    border-radius: var(--s-2);
    width: 100%;
    height: auto;
  }

  &__title {
    font-weight: 700;
    line-height: 1.3;
    font-size: var(--s1);
    color: $dark-blue;
  }

  &__link {
    &:hover {
      text-decoration: none;
    }
  }

  &__peek {
    font-size: var(--s0);
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.suggested-blog-posts {

  &__title {
    display: table;
    font-size: var(--s1);
    line-height: 1;
    background-color: $white;
    border-radius: var(--s-5);
    text-align: center;
    width: 100%;
  }

}

