@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.header {
  &--default {
    @include cms-block();
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: unset;

    .header__container {
      padding: 70px 140px;
    }

    .content {
      position: relative;
      padding: 50px 0 70px 0;
      min-width: 500px;
      width: 50vw;

      > * {
        text-align: left !important;
      }

      h1, .subtitle {
        color: white;
        font-weight: bold;
      }

      .subtitle {
        font-size: 20px;
      }
    }

    @include mobile-screen {
      height: 420px;
      padding: 0 3%;
      margin-bottom: calc(200px - 8vw);
      margin-top: 60px;

      .header__container {
        padding: 0 15px;
      }

      .content {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5% 5%;
        position: relative;
        top: 200px;
        width: auto;
        min-width: auto;

        h1, .subtitle {
          color: #1b3151;
          text-align: center;
        }

        .subtitle {
          margin-top: 16px;
          font-size: var(--s0);
        }
      }
    }
  }
}

