@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.list-block {
  &__items {
    display: flex;
    gap: 60px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  @include mobile-screen {
    &__item {
      gap: 12px;
    }
    &__header {
      gap: 15px;
    }
  }
}
