@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.features {
  display: flex;
  flex-direction: column;
  color: $dark-blue;
  align-items: center;

  &__title {
    text-align: center;
  }

  &__table {
    width: 100%;
    max-width: 800px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 40px;
    align-self: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: var(--highlight-left, 0);
      width: var(--highlight-width, 0);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      z-index: 0;
    }

    th, td {
      padding: 8px 10px;

      img {
        width: 120px;
        height: 40px;
      }

      &:not(:first-child) {
        text-align: center;
        max-width: 200px;
      }

      &:first-child {
        max-width: 420px;
      }
    }

    thead {
      th {
        padding-top: 30px;
      }

      .highlighted {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
    }

    tbody tr:last-child {
      td {
        padding-bottom: 30px;
      }

      .highlighted {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }

    tr:nth-child(even) {
      background-color: #FBF0DC;

      .highlighted {
        background-color: rgba(220, 235, 243, 1);
      }
    }

    tr:nth-child(odd) {

      .highlighted {
        background-color: rgba(235, 242, 245, 1);
      }
    }

    .fa-check-circle {
      color: $primary-green;
    }

    .fa-times-circle {
      color: $light-text;
    }

    i {
      font-size: 20px;
      vertical-align: middle;
    }
  }

  @include mobile-screen {
    &__table {
      table-layout: fixed;

      th, td {
        font-size: var(--s0);
        padding: 10px 8px;

        &:first-child {
          width: 50%;
        }

        &:not(:first-child) {
          max-width: 25%;
        }

        img {
          width: 80px;
          height: 26px;
        }
      }
    }
  }
}
