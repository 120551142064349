@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
@use "../variables" as *;
.feedback-box {
  // box model
  padding: 32px 37px;
  border-radius: 6px;
  background-color: $white;
  box-shadow: $box-shadow;

  // text
  font-weight: bold;

  // successive elements with the same class -> add spacing
  & + & {
    margin-top: 15px;
  }

  &__title {
    font-size: var(--s0);
    font-weight: bold;
    text-align: left;
    margin: 0;
    margin-bottom: 15px;

    &--colored {
      color: $feedback-green;
    }

    &--large {
      font-size: 24px;
      margin-top: 10px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__body-text {
    width: 75%;
  }

  &__body-image {
    width: 25%;
    text-align: center;

    & > img {
      width: 100px;
      height: 100px;
    }
  }

  &__divider {
    box-sizing: border-box;
    border: solid 1px $feedback-divider-color;
    margin: 25px -15px;
  }
}
