@use "../../../mixins/screen-sizes";

.top_margin {
  margin-top: 50px;


  @include screen-sizes.mobile-screen {
    margin-top: 58px;
  }
}
