@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.generic-page-block-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &--nomargin {
    margin-top: 0;
  }

  section {
    width: 100%;
    align-self: center;

    &:last-of-type {
    }

    & > div {
      max-width: 1440px;
      width: 100%;

      &:not(.generic-block__custom) {
        padding: 75px 16px;
      }
    }
  }

  h2 {
    font-weight: 500;
    color: $dark-blue;
    font-size: 40px;
  }

  h1, h2, h3 {
    font-family: $header-font;
  }

  p {
    font-weight: 600;
    font-size: var(--s1);
    line-height: 1.6;
    color: $dark-blue;
  }

  @include large-screen {
    section {
      & > div:not(.generic-block__custom) {
        padding: 75px 16px;
      }
    }
  }

  @include mobile-screen {
    margin-top: 58px;
    &--nomargin {
      margin-top: 0;
    }

    section {
      & > div:not(.generic-block__custom) {
        padding: 75px 16px;
      }

      h2, p {
        text-align: left;
      }
    }

    h2 {
      font-size: 28px;
      margin-bottom: 10px;
    }

    p {
      line-height: 1.6;
    }
  }
}

.generic-block {
  @include cms-block();

  &--full-width {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }

  &__inner {
    width: 100%;
  }

  ul:not(.list-unstyled) {
    list-style: disc;
    padding-left: 40px;
    text-align: left;
  }


  &__subtitle {
    &, p {
      font-size: 24px;
      font-family: $header-font;
      font-weight: 400;
    }

  }

  @include mobile-screen {
    &__subtitle {
      &, p {
        font-size: 20px;
      }
    }
  }
}
