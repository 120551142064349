@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.educational-block {
  @include cms-block();
  margin-top: 60px;
  margin-bottom: 60px;

  &__container {
    border-radius: 25px;
    padding: 50px 100px;
    color: black;
  }

  &__img {
    width: 100%;
    margin: -85px 30px 0 -140px;

    img {
      width: 580px;
      height: auto;
    }
  }

  &__sub-title {
    font-weight: 700;
    font-size: 26px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
    flex: 1 0 60%;
  }

  &__header {
    font-size: 52px;
  }

  &__text {
    flex: 1;
  }

  &__icon {
    width: 65px;
    height: 12px;
  }

  &__stack {
    display: flex;
    flex-direction: column;
    margin-bottom: -12px;
  }

  @include mobile-screen {
    &__container {
      padding: 0 20px 40px;
      margin-top: 80px;
    }

    &__img {
      margin: -80px 0 -20px 0;

      img {
        width: 100%;
      }
    }

    &__right {
      gap: 12px;
    }

    &__header {
      font-size: 30px;
    }

    &__sub-title {
      font-weight: 700;
      font-size: var(--s-1);
    }
  }
}
