@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;


.jumbotron {
  width: 100%;
  margin-bottom: 0;
  color: white;
  background-color: transparent;

  &--header {
    margin-top: 50px;

    .jumbotron__container {
      padding: 50px;

      h2.jumbotron__title {
        font-size: 48px;
      }

      .jumbotron__text {
        z-index: 1;

        p {
          color: white;
          font-size: 20px;
        }
      }
    }
  }

  &__container {
    height: 360px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    place-items: center;
    border-radius: 25px;
    position: relative;
    padding: var(--s0);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 25px;
    }
  }

  .jumbotron__title {
    color: white;
    font-size: 100px;
    font-weight: 500;
    z-index: 1;
    line-height: 1.2;
    text-align: center;
  }

  @include mobile-screen {
    display: none;

    &--header {
      display: flex;

      .jumbotron__container {
        padding: 25px;

        h2.jumbotron__title {
          font-size: 28px;
        }

        .jumbotron__text {

          p {
            font-size: var(--s1);
          }
        }
      }
    }
  }
}

@include mobile-screen {
  .box:has(> .jumbotron) {
    padding: 0;
  }
  .stack > * + *:has(.jumbotron) {
    margin-block-start: 0;
  }
}
