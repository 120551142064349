.switcher {
  display: flex;
  flex-wrap: wrap;
  /* ↓ The default value is the first point on the modular scale */
  gap: var(--gutter, var(--s1));
  /* ↓ The width at which the layout “breaks” */
  --threshold: 60rem;
}

.switcher > * {
  /* ↓ Allow children to grow */
  flex-grow: 1;
  /* ↓ Switch the layout at the --threshold */
  flex-basis: calc((var(--threshold) - 100%) * 999);
}
