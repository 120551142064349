@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.video-block {
  @include cms-block();

  &__container {
    flex-direction: row-reverse;
    align-items: start;
  }

  video, &__text {
    width: 50%;
  }

  &.full-width {
    video {
      width: 100%;
      height: auto;
      border-radius: 25px;
    }
  }

  &__heading {
    &--sub {
      color: $dark-blue;
      margin-bottom: 22px;
    }

    &--descr {
      margin-bottom: 30px;

      p {
        font-size: 20px;
      }
    }
  }

  video {
    height: 350px;
  }

  @include mobile-screen {
    video, &__text {
      width: 100%;
    }

    &__text {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &__container {
      flex-direction: column;
    }

    &__heading {
      &--descr {
        p {
          font-size: var(--s0);
        }
      }
    }
  }
}
