@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.page-list {
  display: flex;
  flex-direction: column;

  &__content.switcher {
    --threshold: 60rem;
    > * {
      flex-basis: 0;
    }
  }

  &__content {

    width: 100%;
    justify-content: space-between;


    .footer__section {
      background-color: $light-blue;
      border-radius: 12px;
      padding: var(--s2);
      min-width: min(100%, 40ch);


      &:first-child {
        flex-grow: 1.5;
      }
    }

    ul {
      list-style: none;

      a {
        font-size: var(--s0);
        color: $dark-blue;
      }
    }
  }
}
