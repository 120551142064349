@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.qna {
  color: $dark-blue;
  width: 100%;
  .switcher {
    --gutter: var(--s2);
    .qna__header {
      flex-grow: 0;
      flex-basis: fit-content;

    }
    .qna__group {
      flex-grow: 2;
    }
  }

  &__container {
    gap: 55px;
  }

  &__header {
    width: 100%;
  }

  h3.qna__question {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: unset;

    i {
      color: inherit;
      transition: transform 0.8s cubic-bezier(0, 1, 0, 1);

      &.rotated {
        transform: rotate(180deg);
      }
    }

    a {
      color: $text;
      text-decoration: none;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__answer {
    padding: 4px 34px 14px 0;

    p {
      font-size: var(--s0);
      margin: 0;
    }
  }

  &__item {
    cursor: pointer;
    overflow: hidden;
    background-color: #FFEFD1;
    padding: 0 20px 8px 20px;
    border-radius: 12px;
  }

  .panel-collapse {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }

  .panel-collapse.show {
    max-height: 1100px;
    transition: max-height 1s ease-in-out;
  }


  @include mobile-screen {
    &__question, &__answer {
      &, p {
        line-height: 24px;
      }
    }
  }
}
