@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.public-page-header {
  &__text {
    p {
      font-size: var(--s1);
    }
  }
}
