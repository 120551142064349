.stack {
  /* ↓ The flex context */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  --space: var(--s1);

  &--large {
    --space: var(--s5);
  }

  > * {
    /* ↓ Any extant vertical margins are removed */
    margin-block: 0;

  }

  > * + * {
    /* ↓ Top margin is only applied to successive elements */
    margin-block-start: var(--space);
  }

  &__nomargin {
    /* ↓ Top margin is only applied to successive elements */
    margin-block-start: 0;
  }
}
