@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
.feedback-point {
  // text styling
  font-size: var(--s0);
  font-weight: bold;

  &__single-point  {
    & + & {
      margin-top: 10px;
    }
  }

  &__label {
    margin: 0 15px 0 0;

    &--light {
      font-weight: 500;
    }
  }

  &__input {
    // box model
    border: none;
    border-radius: 5px;
    background-color: $feedback-input-background;
    line-height: 32px;
    padding: 2px 8px;
    width: 177px;
  }
}
