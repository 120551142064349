@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "../variables" as *;
@use "vars" as *;
@use "../variables" as *;
.feedback-score {
  position: relative;
  border: solid 3px $feedback-green;
  border-radius: 6px;

  line-height: 53px;
  font-size: 24px;

  &--student {
    display: flex;
    align-items: center;
    border: none;
    font-weight: bold;
    text-align: center;
  }

  &__delta {
    display: inline-block;
    margin-left: 8px;
    white-space: nowrap;
    text-align: left;
    line-height: 29px;
    font-size: var(--s0);
    font-weight: bold;
    color: $feedback-green;
  }
}
