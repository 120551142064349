@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.blog-posts-footer {
  &.stack {
    text-align: left;
  }

  &__title {
    color: $dark-blue;
  }

  &__posts {
  }

  .read-more {
    width: fit-content;
  }
}

