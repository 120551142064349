@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
.feedback-points {
  // box model
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  // max-width: 75%;
  margin: 0 auto;
  padding: 24px 0;

  @media only screen and (min-width: 1600px) {
    max-width: 60%;
  }
}
