@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;


.testimonial {
  &.box {
    padding-block: 0;
  }

  &__container {
    display: flex;
    color: $dark-blue;
  }

  &__list {
    transition-timing-function: linear !important;
  }

  &__item {
    display: flex !important;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background-color: #EFF3F5;
    border-radius: 10px;
    min-width: 300px !important;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__avatar {
    --img-size: 60px;
    width: var(--img-size);
    height: var(--img-size);

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__text-block {
    * {
      margin: 0;
    }
  }

  &__name {
    font-weight: 800;
    font-size: 20px;
    margin: 4px 0;
  }

  &__function {
    font-weight: 500;
    font-size: 20px;
  }

  &__text {
    p {
      quotes: "“" "”" "‘" "’";
      font-size: 24px !important;
      font-weight: 500 !important;
      font-family: $header-font;

      &::before {
        content: open-quote;
      }

      &::after {
        content: close-quote;
      }
    }
  }

  @include mobile-screen {
    &__name, &__function {
      font-size: var(--s-1);
    }

    &__text p {
      font-size: 20px !important;
    }
  }
}
