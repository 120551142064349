@use "home";
@use '../components/cms/all';
@use "../components/btn/header-button";
@use "../mixins/cms-block";
@use "../settings/all" as *;

@forward "../components/content/banners";
@forward "../components/interactive-signup/interactive-signup";
@forward "../components/interactive-signup/interactive-signup-overlay";
@forward "../components/interactive-signup/select-course-menu";
@forward "../components/interactive-signup/vars";
@forward "../components/student-signup/course";
@forward "../mixins/cropped-text";
@forward "../mixins/ellipsis";
@forward "../mixins/floating-label";
@forward "../mixins/hexagon";
@forward "../mixins/highlight";
@forward "../mixins/layout";
@forward "../mixins/line-clamp";
@forward "../mixins/on-focus";
@forward "../mixins/screen-sizes";
@forward "../mixins/scroller";
@forward "../mixins/trangle-down";
@forward "../settings/animations";
@forward "../settings/colors";
@forward "../settings/menu";
@forward "../settings/typography";
@forward "../settings/variables";

@forward "../components/layouts/all";
@forward "../components/portal/buttons";
iframe {
  aspect-ratio: 16 / 9;
  width: 100% !important;
}
