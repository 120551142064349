@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
@use "../variables" as *;
.feedback-submit {
  // box model
  width: 225px;
  height: 62px;
  border: none;
  border-radius: 6px;
  background-color: $feedback-green;
  margin-left: -38px;
  box-shadow: 0 2px 6px 0 rgba(62, 140, 85, 0.3);
  opacity: 0.8;
  float: left;

  // text styling
  font-size: var(--s1);
  line-height: 62px;
  text-align: center;
  color: #f2f1f2;

  &:hover {
    font-size: 20px;
  }
}
