@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.header-button {
  white-space: nowrap;
  background-color: rgba(90, 208, 125, 1);
  border-radius: 100px;
  font-size: var(--s0);
  text-transform: uppercase;

  &:not(:first-child) {
    margin-left: 20px;
  }

  &:hover {
    background-color: #5ABF78;
    border-color: #5ABF78;
  }

  &__container {
    display: flex;
    margin-top: 28px;
  }

}
