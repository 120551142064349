@use "../../settings/all" as *;
@use "../../mixins/all" as *;
/* Portal Shadows */
$box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);

// typography
$portal-font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;
$portal-padding: 15px;
$portal-top-margin: 60px;
$portal-container-padding-width: 2em;
