@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
.feedback-avatar {
  display: block;
  margin: 0 auto;
  width: 57px;
  height: $feedback-avatar-height;
  border-radius: 50%;
  background-color: #eeeeee;
}
