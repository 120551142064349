@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
.student-class-feedback, .teacher-student-feedback {
  > .portal > .portal-main {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }
}


.feedback {

  margin: 10px auto; // taken from Zeplin margins
  max-width: 1200px;

  @media screen and (max-width: $tablet-vertical) {
    margin: 10px;
  }

  &--teacher-portal {
    margin: 10px 30px;
  }

  &__return {
    // box model
    display: inline-block;
    border: solid 3px $feedback-green;
    border-radius: 6px;
    margin-bottom: 25px;

    // text
    color: $feedback-font-color;
    font-weight: bold;
    font-size: var(--s-1);
    padding: 1em;

    &:hover {
      background-color: $feedback-green;
      color: $white;
    }
  }

  &__btn-close {
    color: $feedback-font-color;
  }

  &__form {
    margin-left: 38px;
  }

  &__score {
    margin-right: 33%;
  }

  &__preview {
    margin: 0 auto;
    max-width: calc(100% - 200px);

    @media only screen and (min-width: 1600px) {
      max-width: 50%;
    }
  }

  &__horizontal {
    &, &.feedback-ratings__field {
      width: auto;
    }

    label {
      display: none;
    }

    &--with-label {
      justify-content: flex-start;

      &.feedback-ratings__field {
        @media only screen and (min-width: 1600px) {
          width: 300px;
        }
      }

      label {
        display: block;
        width: 200px;
      }
    }

    .star-rating__stars {
      display: flex;
      margin: 0;
    }
  }
}

.feedback-row-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 8px;
}
