@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.btn, a.btn, .nav > li > a.btn {
  border-radius: 6px;
  padding: 0.3em 1em;

  &__narrow {
    font-weight: bold;
    padding: 0.5em 1em;
    border-color: rgba($input-background, 0.8);

    @media screen and (max-width: $xs-screen) {
      padding: 0.5em 1em;
    }
  }

  &__warning {
    color: $badge-color !important;
  }

  &__wide {
    padding: 1rem 3rem;
    font-weight: bold;
    white-space: normal;
    max-width: 100%;

    @media screen and (max-width: $xs-screen) {
      padding: 1rem 2rem;
    }

    @media screen and (max-width: $tablet-vertical) {
      &.btn__class-detail {
        padding: 1rem;
        width: auto;
      }
    }
  }

  &__content {
    display: flex;
    gap: 10px;
    margin: 0 auto;
  }

  &__primary {
    background-color: $primary-green;
    box-shadow: 0 2px 6px 0 rgba(62, 140, 85, 0.3);
    border: 2px solid rgba($primary-green, 0);
    border-radius: 100px;
    color: $dark-blue;

    &:not(:disabled):hover {
      background-color: $primary-green;
      border-color: $primary-green;
      color: white;
      transition: color 0.3s ease-in-out;
    }

    &:focus, &:active, &:active:focus {
      color: $dark-blue;
      outline-color: $primary-green;
    }

    &--inverse {
      background-color: $white;
      color: rgba($primary-green, 0.8);
      border: 3px solid rgba($primary-green, 0.8);
      box-shadow: none;

      &:hover {
        background-color: rgba($primary-green, 0.8);
        color: $white;
        border-color: rgba($primary-green, 0);
      }

      &:focus {
        color: rgba($primary-green, 0.8);;
      }
    }

    &--destructive {
      background-color: $red;

      &:hover {
        background-color: rgba($red, 0.8);
        border-color: rgba($red, 0);
      }
    }
    &--light {
      background-color: $pickford;

      &:hover {
        background-color: rgba($pickford, 0.8);
        border-color: rgba($pickford, 0);
      }
      &:focus {
        color: rgba($pickford, 0.8);;
      }
    }
  }

  &__secondary {
    background-color: rgba(255, 255, 255, 0.6);
    color: $primary-green;
    border: 2px solid $primary-green;
    border-radius: 100px;

    &:not(:disabled):hover {
      border-color: $primary-green;
      color: white;
      background-color: $primary-green;
      transition: all 0.3s ease-in-out;
    }

    &--cancel {
      border: none;
      color: $badge-color;
    }

    &--light {
      color: $grey;
      border-color: #eaeaea;
    }

    &--inverse {
      background-color: rgba($badge-color, 0.8);
      color: $white;
      border: 3px solid rgba($badge-color, 0);
      box-shadow: 0 2px 6px 0 rgba(62, 140, 85, 0.3);

      &:hover {
        background-color: $badge-color;
        color: $white;
      }
    }
  }

  &__primary, &__secondary {
    &--lg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      font-size: var(--s0);
      padding: 15px 20px;
      line-height: 1;
      font-weight: 700;
      text-decoration: none;

      @include mobile-screen {
        font-size: var(--s0);
        line-height: 1;
        padding: 12px 16px;
        height: 50px;
      }
    }
  }

  &__loading {
    background-size: 80px;
    min-height: 50px;
    min-width: 100px;

    @media screen and (max-width: $xs-screen) {
      min-height: 40px;
    }
  }

  &__filter {
    border: 3px solid $input-background;
    color: $text-color;

    &--active {
      border-color: $green;
    }
  }

  &__cta {
    color: $dark-blue;
    background: $primary-green;
    border: 2px solid $primary-green;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 22px;

    &--inverse {
      background: $white;
      color: $primary-green;
      border: none;
    }

    &--blue {
      color: $primary-blue;
      border-color: $primary-blue;
      background: transparent;

      &:hover {
        color: $primary-blue;
      }
    }

    &:hover {
      cursor: pointer;
      background: white;
      color: $dark-blue;
    }

    &:focus, &:active, &:active:focus {
      color: $dark-blue;
      outline-color: $primary-green;
    }

    @include mobile-screen {
      font-size: var(--s0);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1;
    background: transparent;
    color: $dark-blue;

    &:hover {
      i {
        font-weight: bold;
      }
    }
  }
}

.social-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  padding-top: 24px;
  margin: 0 auto;

  &__separator {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 1px;
    margin: 10px 0 36px 0;

    span {
      background: #fff;
      padding: 0 10px;
      font-size: var(--s0);
      color: black;
      letter-spacing: 2px;
    }
  }

  .btn {
    &.btn__social {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 30px;
      border-radius: 30px;
      font-size: 22px;
      border-width: 2px;

      @include xxs-screen {
        font-size: var(--s0);
      }

      i {
        margin-right: 12px;
        font-size: var(--s1);
      }

      &--facebook {
        background: #4267B2;
        color: $white;

        &:hover {
          background: #4e69a2;
          border-color: #4e69a2;
          color: white;
        }
      }

      &--google {
        background-color: $white;
        border-color: black;
        color: black;
      }

      &--info {
        background-color: $white;
        border-color: $primary-green;
        color: $primary-green;

        &:hover {
          background-color: $primary-green;
          border-color: $white;
          color: $white;

        }
      }
    }

    &.btn__cta {
      width: 100%;
    }
  }
}
