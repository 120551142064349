@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
.three-items {
  @include cms-block();

  &__title {
    margin-bottom: 26px;
  }

  &__description {
    p {
      font-size: $text-size;
      line-height: 28px;
      font-weight: normal;
    }
  }

  &__item {
    margin: 30px auto;
  }

  &__img {
    height: 110px;
    object-fit: contain;
    width: 100%;
  }

  &__heading {
    color: $primary-blue;
    margin-top: 28px;
    font-size: var(--s2);
    font-weight: 400;
  }

  @include mobile-screen {
    margin-top: 30px;

    &__item {
      margin: 12px auto;
    }

    &__img {
      height: 55px;
      width: 66px;
    }

    &__heading {
      font-size: var(--s0);
      line-height: 21px;
      font-weight: 400;
      margin-top: 16px;
      hyphens: auto;
    }

    &__description {
      p {
        font-size: var(--s0);
        line-height: 26px;
      }
    }
  }
}
