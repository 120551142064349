@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.simple-header {
  @include cms-block();
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 5%;
  height: 450px;
  align-items: center;
  position: relative;

  .breadcrumbs {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
    max-width: $page-width;
    padding: 0 140px;
  }

  .breadcrumb {
    color: white;

    li.inactive {
      font-style: italic;
    }

    a {
      color: white;
      font-weight: 700;
    }

    margin-block-end: 0;
    background: none;
  }

  &__container {
    padding: 100px 5%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__content {
    max-width: 700px;
    text-align: center;
  }

  &__title {
    font-size: 50px;
    line-height: 55px;
    color: $white;
    font-weight: bold;
    text-align: center;
  }

  @include mobile-screen {
    padding: 50px 15px;

    &__title {
      font-size: 30px;
      line-height: 36px;
    }
  }
}


