@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
@use "../variables" as *;
$feedback-preview-left-width: 100px;
$feedback-preview-avatar-size: 58px;

.feedback-preview {

  font-size: var(--s0);

  &--shift-left {
    transform: translateX(- $feedback-preview-left-width);
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  &__avatar {
    width: $feedback-preview-avatar-size;
    height: $feedback-preview-avatar-size;
    margin: 10px ($feedback-preview-left-width - $feedback-preview-avatar-size) * 0.5;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
  }

  &__text {
    flex-grow: 1;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  &__liked {
    width: $feedback-preview-left-width;
    text-align: center;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 0 10px 10px 10px;
    border-bottom: solid 2px $portal-grey;
  }

  &__cancel-delete {
    border: solid 3px transparent;
    background: transparent;

    @include on-focus {
      border-color: #eaeaea;
    }
  }

  // buttons
  &__edit,
  &__delete,
  &__delete-confirm,
  &__cancel-delete {
    &--hidden {
      display: none;
    }
  }

  &__like {
    min-width: 180px;
    opacity: 0.9;
    padding-top: 0.9em;
    padding-bottom: 0.9em;

    @media screen and (max-width: $xs-screen) {
      min-width: 50px;
    }

    .fa {
      font-size: 21px !important;
      float: left;
    }

    &.btn__secondary {

      .fa {
        color: $feedback-font-color;
        opacity: 0.8;
      }
    }
  }
}


.feedback-preview {
  .btn {
    font-weight: bold;
    font-size: var(--s0);
    margin-left: 10px;

    @media screen and (max-width: $xs-screen) {
      font-size: 12px;
    }
  }

  .btn__primary--inverse {
    color: $feedback-font-color;

    &:hover {
      color: $white;
    }
  }

  .btn__secondary--cancel {
    border: solid 3px #eaeaea;

    &:hover {
      color: $badge-color !important;
      border-color: rgba($badge-color, 0.5);
    }
  }

  .fa {
    font-size: 26px;
    color: $feedback-green;
  }
}
