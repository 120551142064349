@use "../../settings/colors";

.box {
  /* ↓ Padding set to the first point on the modular scale */
  padding: var(--s0);
  /* ↓ Assumes you have a --border-thin var */
  border: var(--border-thin) solid;
  /* ↓ Always apply the transparent outline, for high contrast mode */
  outline: var(--border-thin) transparent;
  outline-offset: calc(var(--border-thin) * -1);

  --color-light: #{colors.$snow};
  --color-dark: #{colors.$dark-blue};

  color: var(--color-dark);
  background-color: inherit;

  * {
    /* ↓ Force colors to inherit from the parent
    and honor inversion (below) */
    color: inherit;
    line-height: 1.6;

  }

  &.invert {
    /* ↓ The color vars inverted */
    color: var(--color-light);
    background-color: var(--color-dark);
  }

  &.transparent {
    background-color: colors.$transparent;
  }

  &--large-end {
    padding-block-end: var(--s5);
  }
}
