@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "vars" as *;
@media screen and (max-width: $xs-screen) {
  $label-recommend-margin: 15px;

  .feedback {
    margin: 0;
    padding: 15px 12px;

    &__form {
      margin-left: 0;
    }
  }

  .feedback-box {
    position: relative;
    padding: 16px 22px;

    &__body-text {
      max-width: 50%;
    }

    &__body-image {
      min-width: 50%;
    }
  }

  .feedback-avatar {
    position: absolute;
    // padding box, height title, margin-top title, margin-bottom title
    top: 16px + 26px + 10px + 15px;
    right: 22px;
  }

  .feedback-recommend {
    padding: 0;
    display: block;

    &__label {
      margin-bottom: $label-recommend-margin;
      max-width: 75%;
      min-height: $feedback-avatar-height + $label-recommend-margin;
    }

    &__radios {
      justify-content: space-around;
      margin: 0 10px; // squeeze them in a bit
    }
  }

  .feedback-ratings {
    margin: 0;
    max-width: 100%;

    &__field {
      width: 100%;
    }
  }

  .star-rating {
    &__stars {
      margin-right: 0;
    }
  }

  .feedback-points {
    margin: 0;
    max-width: 100%;
  }

  .feedback-points-wrapper {
    display:block;
  }
  .feedback-points-field {
    padding: 4px;
  }

  .feedback-point {

    &__single-point--first {
      .feedback-point__label {
        display: block;
        margin: 0 0 15px;
        text-align: center;

        &--light {
          font-weight: bold;
        }
      }
    }

    &__label {
      display: none;
    }

    &__input {
      max-width: 100%;
    }
  }

  .feedback-text {
    padding-top: 15px;
  }

  .feedback-text-box {
    & + & {
      margin-top: 25px;
    }

    &__label {
      margin-bottom: 15px;
    }

    &__text {
      max-width: 100%;
    }
  }

  .feedback-summary {

    &__submit {
      width: 100%;
      text-align: center;
    }

    &__score {
      width: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  .feedback-submit {
    float: none;
    margin: 0;
  }

  .feedback-row-wrapper {
    flex-direction: column;
  }
}
