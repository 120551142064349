@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.carousel {
  h2 {
    margin-bottom: 20px;
  }

  .btn {
    margin: 20px auto;
  }

  &__item {
    background-color: #f1eceb;
    border-radius: 10px;
    mix-blend-mode: normal;
    padding: 24px;
    min-height: 200px;
    height: auto !important;
    display: flex !important;
    flex-direction: column;
  }

  &__text {
    flex: 1;

    &, p {
      font-size: var(--s0);
      font-weight: 500;
    }
  }

  &__name {
    color: $dark-blue;
    font-size: 20px;
    font-weight: 500;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    padding: 20px 0;

    .carousel__btn {
      position: unset;
      height: auto;

      &::after {
        font-size: var(--s-1);
        color: $primary-green;
        border: 1px solid $primary-green;
        padding: 6px 9px;
        border-radius: 50%;
        margin-top: 20px;
      }
    }
  }

  &__pagination {
    position: unset !important;
    text-align: unset !important;
    padding: 10px 0 !important;

    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
    }

    .swiper-pagination-bullet-active {
      background: $primary-green;
    }
  }
}
